import { GetStaticProps } from "next";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { IMainPage } from "@villa/types/mainPage";
import { PagesService } from "@villa/services/PagesService";
import Head from "next/head";
import { MainPage } from "@/lib/pages/MainPage";
import { i18n, useTranslation } from "next-i18next";
import { BASE_FRONT_URL, isDevelopment } from "../lib/api/global";

interface IProps {
  data: IMainPage;
}

export const getStaticProps: GetStaticProps<IProps> = async ({ locale }) => {
  if (locale === "default") {
    return {
      notFound: true,
    };
  }
  const data: IMainPage = await PagesService.getIndexData(locale ?? "en");
  if (isDevelopment) {
    await i18n?.reloadResources();
  }

  return {
    props: {
      ...(await serverSideTranslations(locale ?? "en", ["index"])),
      data,
      headerTheme: "dark",
    },
    revalidate: 360,
  };
};

export default function Index(props: IProps) {
  const data: IMainPage = props.data;

  const { t } = useTranslation("index", { keyPrefix: "meta" });
  return (
    <>
      <Head>
        <title>{t("main.title")}</title>
        <meta property="og:title" content={t("main.title")} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:image" content={"/images/og.png"} />
        <meta name="description" content={t("main.description")} />
        <meta property="og:title" content={t("main.title")} />
        <meta property="og:description" content={t("main.description")} />
        <meta property="og:keywords" content={t("metaKeywords")} />
        <meta property="og:type" content="website" />
        <meta property="og:image:width" content="510" />
        <meta property="og:site_name" content="Villacarte" />
        <meta property="og:url" content={BASE_FRONT_URL} />
        <link rel="canonical" href={`${BASE_FRONT_URL}/en`}></link>
        <link rel="alternate" href={`${BASE_FRONT_URL}/en`} hrefLang="en" />
        <link rel="alternate" href={`${BASE_FRONT_URL}/ru`} hrefLang="ru" />
        <link rel="alternate" href={`${BASE_FRONT_URL}/en`} hrefLang="x-default" />
      </Head>
      <MainPage data={data} />
    </>
  );
}
