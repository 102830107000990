import React from "react";
import { useTranslation } from "next-i18next";
import { IMainPage } from "@villa/types/mainPage";
import { FiveSteps } from "./FiveSteps";
import OffersSlider from "./OffersSlider/OffersSlider";
import styles from "./WeBuildOurselves.module.scss";
import { ListPointStarIcon } from "@/lib/ui/icons";
import { H1, P1Text } from "@/lib/ui/components/Typography";

interface IProps {
  data: IMainPage | undefined;
}

export const WeBuildOurselves = ({ data }: IProps) => {
  const { t } = useTranslation("index");
  const list = [
    t("buyRealEstateOnlyFromTrustedContractors"),
    t("getAProfitCalculation"),
    t("willBeTheFirstToKnow"),
    t("remainConfidentAndCalm"),
  ];

  return (
    <div className={styles.build}>
      <div className={styles.container}>
        <div className={styles.build__advantages}>
          <div className={styles.build__title}>
            <H1 as={'h2'}>
              {t("weAlsoBuildOurselves")} <br />
              {t("weAlsoBuildOurselves2")}
            </H1>
          </div>
          <ul className={styles.build__list}>
            {list.map((item) => {
              return (
                <li className={styles.build__listItem} key={item}>
                  <ListPointStarIcon className={styles.build__listItem_icon} />
                  <P1Text>{item}</P1Text>
                </li>
              );
            })}
          </ul>
        </div>

        <div className={styles.build__sliderBlock}>
          <OffersSlider data={data} />

          <div className={styles.build__stepsBlock}>
            <H1 as={'h2'} className={styles.build__stepsTitle}>
              {t("fiveStep.title1")} <br />
              {t("fiveStep.title2")}
            </H1>

            <FiveSteps />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeBuildOurselves;
