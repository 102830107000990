import { FC } from "react";
import Image from "next/image";
import { IMainPage } from "@villa/types/mainPage";
import { FeedBackBlock } from "@/lib/ui/blocks/common/FeedBackBlock";
import { WeCare } from "@/lib/ui/blocks/common/WeCare";
import { AllServices } from "@/lib/ui/blocks/page-specific/Main/AllServices";
import { BestProjects } from "@/lib/ui/blocks/page-specific/Main/BestProjects";
import { TopBlock } from "@/lib/ui/blocks/page-specific/Main/TopBlock";
import { WeBuildOurselves } from "@/lib/ui/blocks/page-specific/Main/WeBuildOurselves";
import { ShareContent } from "@/lib/ui/blocks/common/ShareContent";
import { JoinTheClub } from "@/lib/ui/blocks/common/JoinTheClub";
import styles from "./MainPage.module.scss";
import { CircleButton } from "@/lib/ui/components/Buttons";
import { ScrollArrow } from "@/lib/ui/icons";

export const MainPage: FC<{ data: IMainPage }> = ({ data }) => {
  const handleClickScroll = () => {
    const element = document.getElementById("section-2");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section className="pt-100-60 pos" id="hero-section">
      <div className={"pos"}>
        <TopBlock />
        <CircleButton
          name="scroll down"
          as={"button"}
          className={styles.btnScroll}
          onClick={handleClickScroll}
          content={<ScrollArrow />}
        />
      </div>

      <AllServices />

      <BestProjects data={data} />

      <FeedBackBlock
        title="requestASelection"
        buttonLabel="requestSelection"
        agreementText="agreementRequestSelection"
        eventName={['others']}
      />

      <div className={`pos ${styles.main__overflow}`}>
        <Image
          sizes="100vw"
          fill
          alt=""
          src={"/weBuildBg.webp"}
          className={"palmsTransparent-bg"}
        />

        <WeBuildOurselves data={data} />

        <JoinTheClub />

        {/* <WeAreTrusted /> */}

        <WeCare />
      </div>

      <ShareContent data={data} />
    </section>
  );
};
