import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import feedBackBg from "@/public/feedBackBg.webp";
import OurMessengers from "@/lib/ui/blocks/common/OurMessengers/OurMessengers";
import styles from "./FeedBackBlock.module.scss";
import cl from "classnames";
import { useObserver } from "@/lib/hooks/useObserver";
import { RequestCallNew } from "@/lib/ui/components/Forms/RequestCallNew";
import { H2, P3 } from "@/lib/ui/components/Typography";
import { LeadType } from "../../../../types/lead-type";

interface Props {
  title?: string;
  subTitle?: string;
  noPadding?: boolean;
  agreementText?: string;
  buttonLabel?: string;
  leadType?: LeadType;
  excludeText?: boolean;
  eventName: string[];
  comment?: string;
}

export const FeedBackBlock = ({
  title = "requestASelection",
  subTitle = "ourManagerWillCallYou",
  noPadding,
  agreementText = "byClickingTheSendRequest",
  buttonLabel = "requestACall",
  leadType = "NOT_SPECIFIED",
  excludeText,
  eventName,
  comment,
}: Props) => {
  const { t } = useTranslation("index");
  const [scrolled, setScrolled] = useState<boolean>(false);
  const feedbackRef = useRef(null);
  useObserver({
    ref: feedbackRef,
    isInterTrue: () => setScrolled(true),
    isInterFalse: () => setScrolled(false),
  });

  useEffect(() => {
    const onScroll = () => {
      // @ts-ignore
      if (document.documentElement.scrollTop > 2900) {
        setScrolled(true);
      }
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div
      className={cl(styles.feedback, noPadding && styles["feedback--nopadding"])}
      ref={feedbackRef}
      id="personal_selection"
    >
      <div
        className={styles.feedback__bg}
        style={{ backgroundImage: `url(${feedBackBg.src})` }}
      />

      <div
        className={cl(styles.feedback__animation, {
          [styles["feedback__animation--start"]]: scrolled,
        })}
      >
        <Image
          sizes="(max-width: 996px) 591px, 755px"
          src={"/images/complex/leaf/white-blue-leaf.webp"}
          alt={""}
          className={styles.feedback__flowerRight}
          fill
        />
      </div>
      <div className={styles.container}>
        <div className={styles.feedback__content}>
          <div className={styles.feedback_formWrapper}>
            <div>
              <H2 className={styles.feedback__title}>
                {t(`form.titles.${title}`) || (
                  <>
                    {t("form.titles.orderAPersonalSelection")} <br />
                    {t("form.titles.orderAPersonalSelection2")}
                  </>
                )}
              </H2>

              {!excludeText && (
                <P3 className={cl(styles.feedback__text)}>
                  {t(`form.subTitles.${subTitle}`)}
                </P3>
              )}
            </div>

            <RequestCallNew
              theme="white"
              className={styles.feedback_form}
              agreementText={agreementText}
              buttonLabel={buttonLabel}
              leadType={leadType}
              eventName={eventName}
              comment={comment}
            />
          </div>
          <OurMessengers />
        </div>
      </div>
    </div>
  );
};
