import React from 'react';
import css from './BgVideo.module.scss';
import { BASE_FRONT_URL } from '../../../api/global';

export const BgVideo = () => {


	return (
		<div className={css.videoWrapper}>
			<video className={css.bgVideo} autoPlay muted playsInline loop>
				<source src={ `${BASE_FRONT_URL}/store/videos/mainPalms.webm` } type="video/webm"/>
				<source src={ `${BASE_FRONT_URL}/store/videos/mainPalms.mp4` } type="video/mp4"/>
				<track kind='captions' style={{display: 'none'}}></track>
			</video>
		</div>
	);
};

export default BgVideo;