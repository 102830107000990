import React from "react";
import { useTranslation } from "next-i18next";
import { BgVideo } from "@/lib/ui/components/Video";
import css from "./TopBlock.module.scss";
import { H1, P15 } from "@/lib/ui/components/Typography";

export const TopBlock: React.FC = () => {
  const { t } = useTranslation("index");
  return (
    <div className={css.topBlock}>
      <BgVideo />

      <div className={css.bgAccentOpacity} />
      <div className={css.textContainer}>
        <H1>VillaCarte</H1>
        <P15>{t("resortReal")}</P15>
      </div>
    </div>
  );
};

export default TopBlock;
