import React from "react";
import { useTranslation } from "next-i18next";
import { IMainPage } from "@villa/types/mainPage";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { useWindowSize } from "@villa/hooks/useWindowSize";
import Button from "@/lib/ui/components/Buttons/Button/Button";
import styles from "./BestProjects.module.scss";
import cl from "classnames";
import { PropertyCard } from "@/lib/ui/components/Cards/PropertyCard";
import { H1, P1 } from "@/lib/ui/components/Typography";
import { ArrowIcon } from "@/lib/ui/icons";
import Link from "next/link";

interface IProps {
  data: IMainPage | undefined;
}

export const BestProjects = ({ data }: IProps) => {
  const {t} = useTranslation("index");
  const { isMobile } = useWindowSize(996);

  return (
    <div className={styles.best}>
      <div className={cl(styles.best__inner, styles.container)}>
        <H1 as={'h2'} className={styles.best__title}>
          {t("theBestProjectsForLife")} <br />
          {t("theBestProjectsForLife2")}
        </H1>

        <Tabs className={styles.best__tabs} selectedTabPanelClassName={""}>
          <TabList className={cl(styles.best__tabList)}>
            <Tab>
              <P1>{t("apartments")}</P1>
            </Tab>
            <Tab>
              <P1>{t("villas")}</P1>
            </Tab>
          </TabList>

          <TabPanel>
            {isMobile
              ? data?.apartments
                  .slice(0, 4)
                  .map((item, index) => (
                    <PropertyCard
                      key={index}
                      {...item}
                    />
                  ))
              : data?.apartments
                  .slice(0, 6)
                  .map((item, index) => (
                    <PropertyCard
                      key={index}
                      {...item}
                    />
                  ))}
          </TabPanel>

          <TabPanel>
            {isMobile
              ? data?.villas
                  .slice(0, 3)
                  .map((item, index) => (
                    <PropertyCard
                      key={index}
                      {...item}
                    />
                  ))
              : data?.villas
                  .slice(0, 6)
                  .map((item, index) => (
                    <PropertyCard
                      key={index}
                      {...item}
                    />
                  ))}
          </TabPanel>
        </Tabs>

        <div className={styles.best_buttonWrapper}>
          <Button
            // label={`${t("showTheRest")} ${data?.offersNumber} ${t("offers")}`}
            label={t("showAll")}
            theme={"light"}
            size={"lg"}
            as={Link}
            href="/sale/catalog"
            icon={<ArrowIcon isRight/>}
          />
        </div>
      </div>
    </div>
  );
};

export default BestProjects;
