import React from "react";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import styles from "./WeCare.module.scss";
import cl from "classnames";
import { H2, P3 } from "@/lib/ui/components/Typography";

export const WeCare = () => {
  const { t } = useTranslation("index");
  return (
    <div className={styles.care}>
      <div className={styles.care__noise} />
      <div className={cl(styles.care__wrapper, styles.container)}>
          <div className={cl(styles.care__textWrapperMob, styles.container)}>
            <H2 className={styles.care_title}>{t("weHaveBeenTakingCare1")} <br/>{t("weHaveBeenTakingCare2")}</H2>
            <P3>{t("inVillaCarteEveryDayWeWork")}</P3>
          </div>
        <div className={styles.care__imageWrapper}>
          <Image src={'/team.webp'} alt={"VillaCarte"} fill 
            sizes="(max-width: 1624px): 1624px, (max-width: 1344px) 1345px, (max-width: 767px) 533px, 100vw"/>
          <div className={styles.care__gradient} />
          <div className={styles.care__textWrapper}>
            <H2 className={styles.care_title}>{t("weHaveBeenTakingCare1")}</H2>
            <H2 className={styles.care_title}>{t("weHaveBeenTakingCare2")}</H2>
            <P3>{t("inVillaCarteEveryDayWeWork")}</P3>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeCare;
