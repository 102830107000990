import React, { FC, ReactNode } from "react";
import { useTranslation } from "next-i18next";
import { useWindowSize } from "@villa/hooks/useWindowSize";
import { Fade } from "react-awesome-reveal";
import styles from "./FiveSteps.module.scss";
import cl from "classnames";
import { ListPointStarIcon } from "@/lib/ui/icons";
import { P2, P3 } from "@/lib/ui/components/Typography";
import { Button } from "@/lib/ui/components/Buttons";

type StepItemProps = {
  stepText: string;
  step: string;
  isMobile?: boolean;
  isLanding?: boolean;
  theme: string;
  withButton: boolean;
};

const steps = [
  {
    stepText: "step1",
    step: "01",
  },
  {
    stepText: "step2",
    step: "02",
  },
  {
    stepText: "step3",
    step: "03",
  },
  {
    stepText: "step4",
    step: "04",
  },
  {
    stepText: "step5",
    step: "05",
  },
];

const StepItem = ({ step, stepText, isMobile, isLanding, theme, withButton }: StepItemProps) => {
  return (
    <div className={styles.fivesteps__step}>
      {isMobile && <div className={cl(
          styles.fivesteps__number, 
          theme === "light" ? styles["fivesteps__number--dark"] : styles["fivesteps__number--light"]
        )}>{step}</div>}
      <ListPointStarIcon className={cl(
        styles.fivesteps__listItem_icon, 
        theme === "light" ? styles["fivesteps__listItem_icon--dark"] : styles["fivesteps__listItem_icon--light"]
        )} />
      <div className={cl(
        styles.fivesteps__stepLine, 
        theme === "light" ? styles["fivesteps__stepLine--dark"] : styles["fivesteps__stepLine--light"]
        )} />
        {theme === "light" ? 
        <P3 className={cl(
          styles.fivesteps__stepText,
          theme === "light" ? styles["fivesteps__stepText--dark"] : styles["fivesteps__stepText--light"]
        )}>{stepText}</P3> : 
        <P2 className={cl(
          styles.fivesteps__stepText,
          theme === "light" ? styles["fivesteps__stepText--dark"] : styles["fivesteps__stepText--light"]
        )}>{stepText}</P2>}
      
      {step === "05" && (
        <ListPointStarIcon
          className={cl(
            styles.fivesteps__listItem_icon, 
            isLanding && withButton ? styles.fivesteps__lastImageLanding : styles.fivesteps__lastImage,
            theme === "light" ? styles["fivesteps__listItem_icon--dark"] : styles["fivesteps__listItem_icon--light"]
          )}
        />
      )}
       {step === "03" && isLanding && (
        <ListPointStarIcon
          className={cl(
            styles.fivesteps__listItem_icon, 
            styles.fivesteps__lastImageThird, 
            theme === "light" ? styles["fivesteps__listItem_icon--dark"] : styles["fivesteps__listItem_icon--light"]
          )}
        />
      )}
    </div>
  );
};

type FiveStepsProps = {
  isLanding?: boolean;
  buttonText?: string;
  buttonOnClick?: () => void;
  theme?: "light" | "dark";
}

export const FiveSteps: FC<FiveStepsProps> = ({buttonText, buttonOnClick, theme = "dark", isLanding = false}) => {
  const { t } = useTranslation("index", { keyPrefix: "fiveStep" });
  const { isMobile } = useWindowSize(996);
  const buttonTheme = theme === "dark" ? "light-invert" : "dark";
  return (
    <div className={cl(styles.fivesteps__stepsWrapper, isLanding && styles.twoRows)}>
      {isMobile ? (
        <>
          {steps.map((step) => (
            <StepItem
              step={step.step}
              key={step.step}
              stepText={t(step.stepText)}
              isMobile
              theme={theme}
              withButton={buttonText ? true : false}
            />
          ))}
        </>
      ) : (
        <>
          {steps.map((step, i) => (
            <div key={step.step} className={styles.fivesteps__stepItem}>
              <Fade delay={300 * i} triggerOnce>
                <h6 className={cl(
                  styles.fivesteps__number, 
                  theme === "light" ? styles["fivesteps__number--dark"] : styles["fivesteps__number--light"])}
                >{step.step}</h6>
              </Fade>
              <StepItem 
                step={step.step} 
                stepText={t(step.stepText)} 
                isLanding={isLanding} 
                theme={theme} 
                withButton={buttonText ? true : false}
              />
            </div>
          ))}
          {buttonText && (
            <div className={styles.fivesteps__stepButtonWrapper}>
                <div className={styles.fivesteps__stepButtonWrapper__button}>
                  <Button as={"button"} label={buttonText} theme={buttonTheme} size={"lg"} onClick={buttonOnClick}/>
                </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
