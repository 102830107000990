import React from "react";
import Image from "next/image";
import { useTranslation } from "next-i18next";
import styles from "./AllServices.module.scss";
import { Button } from "@/lib/ui/components/Buttons/Button/Button";
import AllServicesCards from "./AllServicesCardsWrapper";
import { H2, P3 } from "@/lib/ui/components/Typography";
import { ArrowIcon } from "@/lib/ui/icons";
import Link from "next/link";
import { VideoBlock } from "../../../common/VideoBlock3";

export const AllServices = () => {
  const { t } = useTranslation("index");

  return (
    <div className={styles.allServices} id="section-2">
      <div className={styles.allServices_noise} />
      <div className={styles.allServices_ellipse} />
      <Image
        sizes="(max-width: 1440px) 1320px, 1474px"
        width={1030}
        height={707}
        className={styles.allServices_leaves}
        src={"/image.webp"}
        alt=""
      />
      <div className={styles.allServices_leaf}>
        <Image
          sizes="(max-width: 768px) 120vw, (max-width: 1200px) 996px,(max-width: 1624px) 945px, 1200px"
          src={"/images/complex/leaf/white-blue-leaf.webp"}
          alt={""}
          fill
        />
      </div>

      <div className={styles.container}>
        <div className={styles.allServices_services}>
          <div className={styles.pos}>
            <div>
              <H2 className={styles.h2Subtitle}>
                {t("allServicesInOneCompany")} <br />
                {t("allServicesInOneCompany2")}
              </H2>
              <P3 className={styles.text}>{t("weHelpYouBuyProperty")}</P3>
            </div>
            <Button
              as={Link}
              label={t("learnMore")}
              theme={"dark-invert"}
              size={"lg"}
              href="/about"
              icon={<ArrowIcon isRight />}
            />
          </div>
          <AllServicesCards className={styles.cardsWrapper} />
        </div>
      </div>
      <VideoBlock />
    </div>
  );
};

export default AllServices;
