import { FC } from "react";
import styles from "./PropertyCard.module.scss";
import Image from "next/image";
import { staticResource } from "@/lib/utils/resources";
import { P3, P2 } from "../../Typography";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { toMoney } from "@/lib/utils/toMoney";
import { ArrowIcon } from "@/lib/ui/icons";
import cl from "classnames";
import { useAppSelector } from "@/lib/store/app";
import { SimilarObject } from "@/lib/types/complexPage";

interface IPropertyCard extends SimilarObject {
  className?: string;
}
export const PropertyCard: FC<IPropertyCard> = (props) => {
  const { t } = useTranslation("index");
  const { symbol: cur, value } = useAppSelector((state) => state.settings.currency);
  /* todo проверить цены */
  const money = toMoney(props.price?.[value]) || "нет цены";
  const incomeText = props.projectedIncome
    ? t("projectedIncome")
    : props.rentalPool && !props.guaranteedIncome
    ? t("rentalPool")
    : t("guaranteedIncome");
  const incomeInfo = props.projectedIncome
    ? `${props.projectedIncomePercent}% ${t("declinable.projectedPerYear")}`
    : props.rentalPool && !props.guaranteedIncome && props.rentalPoolPercent
    ? `${props.rentalPoolPercent}/${100 - props.rentalPoolPercent}%`
    : `${props.guaranteedIncomePercent} % ${t("prepositions.for")} ${props.guaranteedIncomePeriod} ${t(
        props?.guaranteedIncomePeriod && props.guaranteedIncomePeriod > 1
          ? "declinable.years"
          : "declinable.year",
      )}`;
  const image = staticResource(props.image) || "/images/placeholder_image.webp";

  return (
    <div className={cl(styles.card, props.className)}>
      <Link href={"/" + props.url}>
        <div className={styles.card__top}>
          <P2>{`${props.type} | ${props.locationName}`}</P2>
        </div>
        <Image
          sizes="(max-width: 586px) 100vw, (max-width: 860px) 45vw, (max-width: 996px) 50vw, 80vw"
          className={styles.card__image}
          src={image}
          alt={props.locationName + " card"}
          fill
        />
        {props.logoImage && (
          <Image
            sizes="(max-width: 586px) 100vw, (max-width: 860px) 45vw, (max-width: 996px) 50vw, 50vw"
            className={styles.card__logo}
            src={staticResource(props.logoImage)}
            alt={props.locationName + " card"}
            fill
          />
        )}

        <div className={styles.card__bottom}>
          <div className={styles.card__bottomLeft}>
            <P3>
              {t("priceFrom")}{" "}
              <span suppressHydrationWarning>
                {cur}
                {money}
              </span>
            </P3>
            <div>
              <P3>{incomeText}:</P3>
              <P3>{incomeInfo}</P3>
            </div>
          </div>
          <div className={styles.card__bottomRight}>
            <P2>{t("more")}</P2>
            <ArrowIcon isRight />
          </div>
        </div>
      </Link>
    </div>
  );
};
